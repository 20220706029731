import PropTypes from 'prop-types';
import React, { useState, Fragment  } from 'react';
import FormLogin from './forms/FormLogin';
import Session from './templates/Session';
import FormBanner from './shared/FormBanner';
import ReactHtmlParser from 'react-html-parser'; 

const HeadingMessage = (props) =>{
  return(
    <>
      { ReactHtmlParser(props.app_content.login_heading)}
    </>
  )
}

const FormFooterMesssage = (props) => {
  return (
    <>
      <h5 className="subHeading">At this site you can easily manage your account:</h5>
      <nav>
        <ul>
          <li>Update your contact info </li>
          <li>Change your password</li>
          <li>Sign up for AutoPay</li>
          <li>See your statement savings</li>
          <li>Request copies of important documents</li>
        </ul>
      </nav>
      <h5 className="subHeading">Questions ?</h5>
      <nav>
        <ul>
          <li>Call Membership Services at {props.env_vars.ui_menu_phone}</li>
        </ul>
      </nav>
    </>
    
  )
}
const MainPage = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  
  return (
    <div className="mainPage">
      <Session form={<FormLogin submitForm={submitForm} {...props}/>} formBanner = {<a href="https://solarfarms.formstack.com/forms/solar_landscape_step1_r2"><FormBanner {...props}/></a>} 
        formFooter={<FormFooterMesssage {...props}/>}
        heading={<HeadingMessage {...props}/>} {...props}/>
    </div>
  );
};

MainPage.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default MainPage
